const config = {
  //apiUrl: "http://3.7.87.182:4000/api/",
  apiUrl: "https://verifyapi.idtfy.in/api/", //http://3.7.87.182:4000/
  // apiUrl: "http://localhost:4000/api/",
  // apiUrl: 'http://65.1.61.205:4000/api/',//http://3.7.87.182:4000/
  apikey: "adaadc2361024c7bb73c457ae1e17b79",
  s3Image: "https://englishhub.s3-us-west-2.amazonaws.com",
  instituteType: [
    { id: 1, name: "Boys" },
    { id: 2, name: "Girls" },
    { id: 3, name: "Boys&Girls" },
  ],
  roles: [
    { id: 1, name: "Admin" },
    { id: 2, name: "Tutor" },
    { id: 3, name: "Student" },
    { id: 4, name: "Translator" },
    { id: 5, name: "Guest" },
  ],
  dayArray: [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Sunday" },
  ],
  notificationType: [
    { id: 1, name: "Email" },
    { id: 2, name: "Message" },
    { id: 3, name: "Push" },
    { id: 4, name: "Call" },
  ],
  subjectType: [
    { id: 1, name: "Core" },
    { id: 2, name: "Elective" },
  ],
};
export default config;
